import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-[#635BFF] ">
      <div className=" container mx-auto">
        <div className="flex justify-center pt-14 ">
          <img src="img/footer/logo.svg" className="cursor-pointer" alt="" />
        </div>
        <div className="text-white text-center mt-6 mx-5 md:mx-0">
          The Stripe name and logos are trademarks or service marks of Stripe,
          Inc. or its affiliates in the U.S. and other countries.
        </div>
        <div className="flex justify-center mt-5 gap-x-6">
          <Link to="" target="_blank">
          <img
            src="img/footer/facebookicon.svg"
            alt=""
            className="hover:translate-y-px cursor-pointer"
          /></Link>
          <Link to='https://x.com/Payposapp' target="_blank">
          <img
            src="img/footer/twittericon.svg"
            alt=""
            className="hover:translate-y-px cursor-pointer"
          /></Link>
          <Link to="https://www.linkedin.com/company/payposapp/about/" target="_blank">
          <img
            src="img/footer/linkdeinicon.svg"
            alt=""
            className="hover:translate-y-px cursor-pointer"
          /></Link>
        </div>
        <div className="text-white text-center mt-6 pb-14">
        © 2024 PayPOS Pty Ltd. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import "./banner.css";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";

function Banner() {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col lg:flex-row justify-center  lg:justify-between items-center mt-14 ">
        <div className="lg:order-2 order-1">
          {/* <ScrollAnimation
            animateIn="animate__bounceIn"
            animatePreScroll={true}
            initiallyVisible={true}
          > */}
            <img
              src="img/logo/banner-mobile.png"
              className="  max-w-[20rem] md:max-w-[23rem] lg:max-w-[100%] md:mx-0 animate__animated animate__pulse animate__infinite animate__slower  "
              alt=""
            />
          {/* </ScrollAnimation> */}
        </div>
        <div className="lg:w-5/12 md:order-1 order-2 mt-7 mx-5 ">
          <ScrollAnimation
            animateIn="animate__fadeInRight"
            animatePreScroll={true}
            initiallyVisible={true}
          >
            <div className="animate__animated animate__fadeInRight">
              <div className=" font-bold font-Sans lg:leading-[54px] text-2xl md:text-4xl lg:text-5xl  ">
                Tap Or Enter, Accept Card Payments With Just Your Phone
              </div>

              <div className="mt-5 text-base sm:text-xl md:text-2xl font-medium md:leading-9">
                PayPOS is a point of sale app powered by Stripe, enabling you to
                charge customers worldwide in seconds. Takes less than 5 minutes
                to set up.
              </div>
            </div>
          </ScrollAnimation>

          <div className="flex mt-8 cursor-pointer justify-center md:justify-start gap-x-5">
            <div>
              <Link to={""}>
                <img
                  src="img/logo/google-play.png"
                  className="md:w-full w-[168.75px] hover:scale-95"
                  alt=""
                />
              </Link>
            </div>
            <div className="">
              <Link to={""}>
                <img
                  src="img/logo/apple-store.png"
                  className="md:w-full w-[149.58px] hover:scale-95"
                  alt=""
                />
              </Link>
            </div>
          </div>
          {/* <div className="mt-7 flex justify-center md:block">
            <Link to={""}>
              <img src="img/logo/stripe-verified.svg" className=" " alt="" />
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Banner;

import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
function DownloadSection() {
  return (
    <ScrollAnimation
      animateIn="animate__fadeInUp" // Ensure you are using the correct class from animate.css
      // animatePreScroll={true}
    >
      <div className=" bg-[#635BFF0A] overflow-x-hidden">
        <div className=" container mx-auto grid md:grid-cols-3 grid-rows-1 py-8 md:py-16 items-center justify-center">
          <ScrollAnimation
            animateIn="animate__fadeInLeftBig" // Ensure you are using the correct class from animate.css
            animatePreScroll={true}
          >
            <div className="md:col-span-1 row-span-1 mx-6">
              <img src="img/Download/phone.png" alt="" />
            </div>
          </ScrollAnimation>

          <div className="md:col-span-2 row-span-1 md:mx-24 my-auto font-medium text-sm md:text-lg mx-7 mt-10 ">
          <div className="flex mt-8 cursor-pointer justify-center md:justify-start gap-x-5">
            <div>
              <Link to={""}>
                <img
                  src="img/logo/google-play.png"
                  className="md:w-full w-[168.75px]  hover:translate-y-px"
                  alt=""
                />
              </Link>
            </div>
            <div className="">
              <Link to={""}>
                <img
                  src="img/logo/apple-store.png"
                  className="md:w-full w-[149.58px] hover:translate-y-px"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="mt-6">
            Download PayPOS now ! The most reliable Point of sale App for Stripe
            payments. Put Google Play and App Store Badge on the last part of
            the landing page.
            </div>
          
            <div className="flex justify-center md:block mt-3">
              <button className="bg-[#635BFF] text-white px-6 py-3 rounded-md mt-4 hover:bg-[#534af1] hover:translate-y-px w-[164px] md:w-auto h-[50px]">
                Download now
              </button>
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default DownloadSection;

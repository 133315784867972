import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Header from "../../components/header/Header";
import Banner from "../banner/Banner";
import Features from "../features/Features";
import Currency from "../currencySection/Currency";
import ReviewSection from "../reviewSection/ReviewSection";
import DownloadSection from "../downloadSection/DownloadSection";
import Footer from "../../components/footer/Footer";
import Aibot from "../Aibot/Aibot";

function Home() {
  useEffect(() => {
    // Function to scroll to top
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Call scrollToTop when component mounts
    scrollToTop();

    // Handle scroll position on refresh
    const handleBeforeUnload = () => {
      scrollToTop(); // Not necessary, but for consistency
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <div className="h-[933px]">
        <Header />
        <Banner />
      </div>
      <Features />
      <Currency />
      <ReviewSection />
      <DownloadSection />
      <Aibot />
      <Footer />
    </>
  );
}

export default Home;
